

































































































































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { gsap } from "gsap";

const contactModule = namespace("contact");
@Component
export default class Contact extends Vue {
  @contactModule.Getter companyName!: string;
  @contactModule.Getter companyNumber!: number;
  @contactModule.Getter iainPhoneNumber!: string;
  @contactModule.Getter bradleyPhoneNumber!: string;
  @contactModule.Getter emailAddress!: string;
  @contactModule.Getter bradleyEmailAddress!: string;
  @contactModule.Getter googleMapsInformation!: Array<string>;

  displayContent = gsap.timeline();
  animate() {
    this.displayContent.play();
  }
  mounted() {
    this.displayContent.from("main .wrapper", {
      duration: 0.5,
      opacity: 0,
    });
    this.animate();
  }
}
